import React from 'react';
import './HeroList.scss';

const TileList = ({ id, header, srHeader, children }) => (
  <div className={`c-hero-list`}>
    {header ? (
      <h2 className="f-header-2" id={id}>
        {header}
      </h2>
    ) : (
      <h2 className="u-sr-only" id={id}>
        {srHeader}
      </h2>
    )}

    <ul aria-labelledby={id}>
      {children &&
        React.Children.map(children, (child, i) => (
          <li className="c-hero-list__item" key={child.props.id}>
            {child}
          </li>
        ))}
    </ul>
  </div>
);

export default TileList;
