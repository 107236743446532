import React from 'react';
import { graphql } from 'gatsby';
import GlobalLayout from '../components/GlobalLayout';
import Seo from '../components/seo';
import LinkCard from '../components/LinkCard';
import HeroList from '../components/HeroList';
import TileList from '../components/TileList';

const caseStudyNameOrder = [
  'outrider',
  'nike',
  'ekoihub',
  'nhm',
  'oasis',
  'henry',
  'monsters',
  'currents',
];

const IndexPage = props => {
  return (
    <GlobalLayout>
      <Seo title="Philly-based Creative Front End Developer" />
      <div className="o-page-pad">
        <section className="o-heading-section">
          <h1 className="f-hero">
            Creative developer/designer based in Philadelphia.{' '}
            <span className="f-knock">Available for select freelance.</span>
          </h1>
        </section>
      </div>
      <section className="o-page-pad" id="selected-work">
        <HeroList srHeader={'Featured Work'} id="featuredworklist">
          {props.data.caseStudies &&
            caseStudyNameOrder.map(name => {
              const edge = props.data.caseStudies.edges.find(edge => {
                return edge.node.frontmatter.name === name;
              });
              return (
                <LinkCard
                  externalLink={edge.node.frontmatter?.external}
                  url={edge.node.frontmatter.path}
                  title={edge.node.frontmatter.title}
                  description={edge.node.frontmatter.description}
                  video={
                    edge.node.frontmatter.featuredVideo &&
                    edge.node.frontmatter.featuredVideo.publicURL
                  }
                  videoAspect={edge.node.frontmatter.featuredVideoAspect}
                  imageAlt={edge.node.frontmatter.featuredImageAlt}
                  imgData={
                    edge.node.frontmatter.featuredImage &&
                    edge.node.frontmatter.featuredImage.childImageSharp.fluid
                  }
                  key={edge.node.id}
                />
              );
            })}
        </HeroList>
      </section>

      <section
        className="o-page-pad--xl u-bk-off-black"
        id="notes-and-sketches"
      >
        <TileList header="Notes &amp; Sketches" id="notes-and-sketches-header">
          {props.data.rnd &&
            props.data.rnd.edges.map(edge => (
              <LinkCard
                isSmall={true}
                url={edge.node.frontmatter.path}
                title={edge.node.frontmatter.title}
                description={edge.node.frontmatter.description}
                date={edge.node.frontmatter.date}
                tags={edge.node.frontmatter.tags}
                video={
                  edge.node.frontmatter.featuredVideo &&
                  edge.node.frontmatter.featuredVideo.publicURL
                }
                videoAspect={edge.node.frontmatter.featuredVideoAspect}
                imageAlt={edge.node.frontmatter.featuredImageAlt}
                imgData={
                  edge.node.frontmatter.featuredImage &&
                  edge.node.frontmatter.featuredImage.childImageSharp.fluid
                }
                externalLink={edge.node.frontmatter.external}
                key={edge.node.id}
              />
            ))}
        </TileList>
      </section>

      <section className=" o-page-pad--xl" id="news-and-press">
        <TileList
          tight={true}
          header="News &amp; Press"
          id="news-and-press-header"
        >
          {props.data.news &&
            props.data.news.edges.map(edge => (
              <LinkCard
                isSmall={true}
                externalLink={edge.node.frontmatter.external}
                title={edge.node.frontmatter.title}
                description={edge.node.frontmatter.description}
                date={edge.node.frontmatter.date}
                tags={edge.node.frontmatter.tags}
                key={edge.node.id}
              />
            ))}
        </TileList>
      </section>
    </GlobalLayout>
  );
};

export const pageQuery = graphql`
  query IndexQuery {
    caseStudies: allMdx(
      filter: { frontmatter: { type: { eq: "case study" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            name
            external
            path
            title
            type
            date(formatString: "MM.DD.YYYY")
            description
            tags
            featuredVideo {
              publicURL
            }
            featuredVideoAspect
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            featuredImageAlt
          }
        }
      }
    }
    rnd: allMdx(
      filter: { frontmatter: { type: { eq: "rnd" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            external
            type
            date(formatString: "MM.DD.YYYY")
            description
            tags
            featuredVideo {
              publicURL
            }
            featuredVideoAspect
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 300) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            featuredImageAlt
          }
        }
      }
    }
    news: allMdx(
      filter: { frontmatter: { type: { eq: "news" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          frontmatter {
            external
            title
            type
            date(formatString: "MM.DD.YYYY")
            description
            tags
          }
        }
      }
    }
  }
`;

export default IndexPage;
